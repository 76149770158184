import React, {Component} from "react";
import ShowMoreText from "react-show-more-text";
import './styles/Table.sass'
import Accordions from "./Accordions";
import {Tooltip as ReactTooltip} from "react-tooltip";
import Icon from "./Icon";
import Highlighter from "react-highlight-words";

class Table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            input: null,
            button_id: null,
            toggled: [],
            data_keys: ['vadis_data_2']
            // data_keys: ['vadis_data', 'vadis_data_2']
        };
        this.handleClick = this.handleClick.bind(this)
        this.toggleMoreLess = this.toggleMoreLess.bind(this)
    }

    handleClick(id, ind) {
        this.setState({
            button_id: ind
        })
        this.props.getParams(id)
    }

    toggleMoreLess(ind){
        if(this.state.toggled.includes(ind)){
            let filteredArray = this.state.toggled.filter(item => item !== ind)
            this.setState({toggled: filteredArray});
        }
        else{
            this.setState({
                toggled: [...this.state.toggled, ind]
            })
        }

    }

    render() {
        // console.log(this.state.toggled)
        let sorted_ssoar_docs = this.props.ssoar_docs
        // let sorted_ssoar_docs = this.props.sortFilter==='random_ids'?this.props.ssoar_docs.sort((a,b) => (b._score > a._score) ? 1 : ((a._score > b._score) ? -1 : 0))
        //     :this.props.sortFilter==='year_desc_ids'?this.props.ssoar_docs.sort((a,b) => (b._source.date_info.issue_date > a._source.date_info.issue_date) ? 1 : ((a._source.date_info.issue_date > b._source.date_info.issue_date) ? -1 : 0))
        //     // : this.props.sortFilter==='best_match'? this.props.ssoar_docs.sort((a,b) => (b._score > a._score) ? 1 : ((a._score > b._score) ? -1 : 0))
        //         : this.props.sortFilter==='vs_count_desc_ids'? this.props.ssoar_docs.sort((a,b) => (Object.keys(b.vadis_data.variable_sentences).length > Object.keys(a.vadis_data.variable_sentences).length) ? 1 : ((Object.keys(a.vadis_data.variable_sentences).length > Object.keys(b.vadis_data.variable_sentences).length) ? -1 : 0))
        //             : this.props.ssoar_docs
        // // let sorted_ssoar_docs = this.props.ssoar_docs.sort((a,b) => (b.vadis_data.variable_sentences.length < a.vadis_data.variable_sentences.length) ? 1 : ((a.vadis_data.variable_sentences.length < b.vadis_data.variable_sentences.length) ? -1 : 0))
        return <table key={'table'} className={Object.keys(sorted_ssoar_docs).length>1?"table table-striped table-hover":"table"}>
            <tbody key={'table-body'}>
            {sorted_ssoar_docs.map((doc, ind) => (
                // 'vadis_data' in doc && 'summary' in doc['vadis_data'] && doc['vadis_data']['summary'] !=='Autogenerated summary is not available.' ?
                <tr key={'tr' + ind}
                    // className='cursor' onClick={() => this.handleClick(doc['_id'], ind)}
                >
                    <td key={'td' + ind} className='row'>
                        <div key={'title' + ind} className='col-11'>
                            <br/>
                            <h6>
                                <u className='bg-color'
                                   id={doc['_id']}
                                   onClick={() => this.handleClick(doc['_id'], ind)}
                                   // onClick={() => this.handleClick(doc['_id'].split('-')[2], ind)}
                                >
                                    {doc['_source']['title']}
                                    {/*&nbsp;&nbsp; <span className="fw-lighter">[{doc['_id']}]</span>*/}
                                </u>
                                <ReactTooltip
                                    anchorId={doc['_id']}
                                    place="top"
                                    className="tooltip-clr"
                                    content="Click on the title of a scientific article for details"/>
                                {'date_info' in doc['_source'] && 'issue_date' in doc['_source']['date_info'] ?
                                    <span id={'date_info' + ind} className="fw-normal bg-color" key='src_journal'>
                                    <i> ({doc['_source']['date_info']['issue_date']}) </i>
                                </span> : 'dates' in doc['_source'] && 'issue_date' in doc['_source']['dates'] ?
                                        <span className="orange-color fw-normal" key='src_journal'>
                                        <i> ({doc['_source']['dates']['issue_date']}) </i>
                                    </span> : null}
                                {'language' in doc['_source'] && doc['_source']['language'] ?
                                    <>
                                                    <span id={'language' + ind} className='fw-normal bg-color' key='src_journal'>
                                                        <i> ({doc['_source']['language']}) </i>
                                                    </span>
                                        <ReactTooltip anchorId={'language' + ind}
                                                      place="top"
                                                      className="tooltip-clr"
                                                      content="Document's language"/>
                                    </>
                                    : null
                                }
                            </h6>
                        </div>
                        <div key={'url' + ind} className='col-1 text-end bg-color pdg'>
                            {'url' in doc['_source'] ?
                                <>
                                <a
                                    id={'pdf_'+ind}
                                    href={doc['_source']['url']}
                                    target='_blank'
                                    rel='noreferrer'>
                                    <Icon iconName='PDF'/>
                                    {/*[pdf]*/}
                                </a>
                                <ReactTooltip
                                    anchorId={'pdf_'+ind}
                                    place="top"
                                    className="tooltip-clr"
                                    content="Open PDF file in a new tab"/>
                                </>
                                :
                                null
                            }
                            {/*{doc['_source']['pdf']}*/}
                        </div>
                        <div key={'authors' + ind} className='col-12 small-txt bg-color'>
                            {'authors' in doc['_source'] ? doc['_source']['authors'].map((author, i) => (
                                <>
                                        <span id={'author' + i + ind} className="fw-normal" key={i}>
                                            &#x2022; {'name' in author ? author['name'] : author['name_string']}&nbsp;
                                        </span>
                                    <ReactTooltip anchorId={'author' + i + ind}
                                                  place="top"
                                        // variant="light"
                                                  className="tooltip-clr"
                                        // float={true}
                                                  content="Author"/>
                                </>
                            )) : null}
                        </div>
                        {
                            'source_info' in doc['_source'] && 'src_journal' in doc['_source']['source_info'] ?
                                <div key={'source_info' + ind} className='col-12 small-txt bg-color'>
                                <span className="fw-normal" key='src_journal'>
                                    <i id={'source_info' + ind}> {doc['_source']['source_info']['src_journal']} </i>
                                    <ReactTooltip anchorId={'source_info' + ind}
                                                  place="top"
                                        // variant="light"
                                                  className="tooltip-clr"
                                        // float={true}
                                                  content="Source"/>
                                </span>
                                </div> : null
                        }
                        {
                            'abstract' in doc['_source'] && doc['_source']['abstract'] ?
                                <>
                                    <div id={'abstract' + ind} className='col-12 small-txt align-txt'>
                                        <ShowMoreText width={0}
                                                      lines={this.props.detailedView ? 0 : 1}
                                                      anchorClass='show-more-less-clickable bg-color'
                                                      more="Show more"
                                                      less="Show less"
                                                      className="content-css"
                                                      expanded={false}
                                                      truncatedEndingComponent={"... "}
                                            // onClick={()=>this.toggleMoreLess(ind)}
                                        >
                                            {/*<span>This pape<b>r addresses the 'old' topic of contextua</b>l effects on voting behaviour. Current multilevel analysis techniques provide a powerful tool to (re-)examine such regional influences on individual voting decisions. We apply multilevel analysis to German electoral data. Our results prove evidence of two contextual effects. The unemployment level of the district has a positive effect on SPD support, which confirms the local equivalent of the economic vote model. Furthermore the global strength of a party in a district has a positive effect on the individual decision to support that party. This is a confirmation of what has been labelled as breakage. Both effects demonstrate the continuing impact of the locality on individual vote preferences in Germany." (author's abstract)</span>*/}
                                            {/*<span>{doc['_source']['abstract'].replace(doc['_source']['abstract'].slice(10, 50), `<b>${doc['_source']['abstract'].slice(10, 50)}</b>`)}</span>*/}
                                            {/*`<b>${boldText}</b>`*/}

                                            {/*{*/}
                                            {/*        // !this.state.toggled.includes(ind) && !this.props.detailedView?*/}
                                            {/*        //     <span>{doc['_source']['abstract']}</span>*/}
                                            {/*        // :*/}
                                            {/*            <>*/}
                                            {/*                /!*<span>{doc['_source']['abstract'].split(doc['_source']['abstract'].slice(250, 300))[0]}</span>*!/*/}
                                            {/*                /!*<span><b id={'extreme_summary_' + ind}>{doc['_source']['abstract'].slice(250, 300)}</b></span>*!/*/}
                                            {/*                /!*<span>{doc['_source']['abstract'].split(doc['_source']['abstract'].slice(250, 300))[1]}</span>*!/*/}
                                            {/*                */}
                                            {/*                <span>{doc['_source']['abstract'].split(doc['vadis_data']['extractive_summary'])[0]}</span>*/}
                                            {/*                <span><b id={'extreme_summary_' + ind}>{doc['_source']['abstract'].slice(doc['_source']['abstract'].indexOf(doc['vadis_data']['extractive_summary']), doc['vadis_data']['extractive_summary'].length)}</b></span>*/}
                                            {/*                <span>{doc['_source']['abstract'].split(doc['vadis_data']['extractive_summary'])[1]}</span>*/}
                                            {/*                <ReactTooltip*/}
                                            {/*                    anchorId={'extreme_summary_' + ind}*/}
                                            {/*                    // anchorSelect=".extreme_summary_bold"*/}
                                            {/*                    place="top"*/}
                                            {/*                    className="tooltip-clr z-pos"*/}
                                            {/*                    content="Extractive Summary"*/}
                                            {/*                />*/}
                                            {/*            </>*/}
                                            {/*}*/}

                                            {/*{*/}
                                            {/*    'extractive_summary' in doc['vadis_data'] && !this.state.toggled.includes(ind) && !this.props.detailedView?*/}
                                            {/*        // console.log(doc['_source']['abstract'].slice(doc['_source']['abstract'].indexOf(doc['vadis_data']['extractive_summary']), doc['vadis_data']['extractive_summary'].length))*/}
                                            {/*        <span>*/}
                                            {/*        <span>{doc['_source']['abstract'].split(doc['vadis_data']['extractive_summary'])[0]}</span>*/}
                                            {/*        <span><b id={'extractive_summary_' + ind}>{doc['_source']['abstract'].slice(doc['_source']['abstract'].indexOf(doc['vadis_data']['extractive_summary']), doc['vadis_data']['extractive_summary'].length)}</b></span>*/}
                                            {/*        <span>{doc['_source']['abstract'].split(doc['vadis_data']['extractive_summary'])[1]}</span>*/}
                                            {/*        <ReactTooltip*/}
                                            {/*            anchorId={'extractive_summary_' + ind}*/}
                                            {/*            // anchorSelect=".extreme_summary_bold"*/}
                                            {/*            place="bottom"*/}
                                            {/*            className="tooltip-clr z-pos"*/}
                                            {/*            content="Extractive summary"*/}
                                            {/*        />*/}
                                            {/*        </span>*/}
                                            {/*        :*/}
                                            {/*        <span>{doc['_source']['abstract']}</span>*/}
                                            {/*}*/}

                                            <Highlighter
                                                id={'extractive_summary_' + ind}
                                                highlightClassName="extractive_summary_bold"
                                                // searchWords={['']}
                                                searchWords={[doc['_source']['vadis_data']['ext_summary']]}
                                                autoEscape={true}
                                                textToHighlight={doc['_source']['abstract']}
                                            />
                                            {/*<ReactTooltip*/}
                                            {/*    anchorId={'extreme_summary_' + ind}*/}
                                            {/*    anchorSelect=".extreme_summary_bold"*/}
                                            {/*    place="top"*/}
                                            {/*    className="tooltip-clr z-pos"*/}
                                            {/*    content="Extreme Summary"*/}
                                            {/*/>*/}
                                            {/*<span>{doc['_source']['abstract']}</span>*/}
                                            {/*<p><small> - Note: <b>Emphasized text </b>above indicates Extreme Summary from author's abstract.</small></p>*/}
                                        </ShowMoreText>
                                        <br/>
                                    </div>
                                    <ReactTooltip anchorId={'abstract' + ind}
                                                  place="top"
                                        // variant="light"
                                                  className="tooltip-clr"
                                        // float={true}
                                                  content="Author's abstract"/>
                                </>
                                : null
                        }
                        {'vadis_data' in doc['_source'] ?
                            <i className='col-10'>
                                {/*<b className="col-md-6 bg-color pdg">Data 1: </b>*/}
                                {
                                    'abs_summary' in doc['_source']['vadis_data'] ?
                                        <div key={'summary' + ind} className='small-txt align-txt'>
                                            <span id={'summary' + ind}>{doc['_source']['vadis_data']['abs_summary']}</span>
                                            <ReactTooltip anchorId={'summary' + ind}
                                                          place="top"
                                                          // variant="light"
                                                          className="tooltip-clr"
                                                          // float={true}
                                                          content="Automatically generated summary of the article"/>
                                        </div>
                                        : null
                                }
                                {/*<br/>*/}
                            </i> : null
                        }
                        {
                            'variable_sentences' in doc['_source']['vadis_data_2'] ?
                                <div key={'var_sents2' + ind} className='col-2 text-end'>
                                        <span id={'sentences2' + ind} className='my-badge small-txt'>
                                            Total sentences: {Object.keys(doc['_source']['vadis_data_2']['variable_sentences']).length}
                                        </span>
                                    <ReactTooltip anchorId={'sentences2' + ind}
                                                  place="top"
                                        // variant="light"
                                                  className="tooltip-clr"
                                        // float={true}
                                                  content="Total amount of extracted sentences with linked variables"/>
                                </div> : null
                        }

                        {/*<b className="col-md-3 bg-color text-end pdg">Version 1: </b>*/}
                        {/*{*/}
                        {/*    'variable_sentences' in doc['_source']['vadis_data'] ?*/}
                        {/*        <div key={'var_sents' + ind} className='col-3 pdg col-with-border'>*/}
                        {/*                <span id={'sentences' + ind} className='my-badge small-txt'>*/}
                        {/*                    Total sentences: {Object.keys(doc['_source']['vadis_data']['variable_sentences']).length}*/}
                        {/*                </span>*/}
                        {/*            <ReactTooltip anchorId={'sentences' + ind}*/}
                        {/*                          place="top"*/}
                        {/*                // variant="light"*/}
                        {/*                          className="tooltip-clr"*/}
                        {/*                // float={true}*/}
                        {/*                          content="Total amount of extracted sentences with linked variables"/>*/}
                        {/*        </div> : null*/}
                        {/*}*/}
                        {/*<b className="col-md-3 bg-color text-end pdg">Version 2: </b>*/}
                        {/*{*/}
                        {/*    'variable_sentences' in doc['_source']['vadis_data_2'] ?*/}
                        {/*        <div key={'var_sents2' + ind} className='col-3 pdg'>*/}
                        {/*                <span id={'sentences2' + ind} className='my-badge small-txt'>*/}
                        {/*                    Total sentences: {Object.keys(doc['_source']['vadis_data_2']['variable_sentences']).length}*/}
                        {/*                </span>*/}
                        {/*            <ReactTooltip anchorId={'sentences2' + ind}*/}
                        {/*                          place="top"*/}
                        {/*                // variant="light"*/}
                        {/*                          className="tooltip-clr"*/}
                        {/*                // float={true}*/}
                        {/*                          content="Total amount of extracted sentences with linked variables"/>*/}
                        {/*        </div> : null*/}
                        {/*}*/}
                        {
                            this.props.detailedView ?
                                this.props.loading ?
                                    <div key={'dvl'} className="d-flex justify-content-center">
                                        <div className="spinner-border bg-color" role="status">
                                            {/*<span className="sr-only">Loading...</span>*/}
                                        </div>
                                    </div>
                                    :
                                    // 'vadis_data' in doc['_source'] || 'vadis_data_2' in doc['_source'] ?
                                    // : 'vadis_data' in doc['_source'] && !('error' in doc['_source']['vadis_data']) ?
                                            <div className="container">
                                                <br/>
                                                <div className="row">
                                                    {
                                                        this.state.data_keys.map((d_key, d_ind) => (
                                                            // console.log(d_key)
                                                            // col-with-border
                                                            d_key in doc['_source']?
                                                                <div className="col-md-12">
                                                                    <Accordions key={d_key} result={doc['_source'][d_key]} pdfId={doc['_id'].split('-')[2]} data_key={d_key}  sents_range_score={d_key==="vadis_data"? [0.75, 1.0] : [0, 1.0]}/>
                                                                </div>
                                                                :
                                                                <div className="col-md-6">
                                                                    <span className='orange-color'>
                                                                        No data available for {d_key}!!!
                                                                    </span>
                                                                </div>
                                                        // <div className="col-md-6">
                                                        // <Accordions key={'Accordions_' + ind} result={doc['_source']['vadis_data']} pdfId={doc['_id'].split('-')[2]} data='data1'/>
                                                        // </div>
                                                        // <div className="col-md-6">
                                                        // <Accordions key={'Accordions2_' + ind} result={doc['_source']['vadis_data_2']} pdfId={doc['_id'].split('-')[2]} data='data2'/>
                                                        // </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        // : <div key={'dv'} className='d-flex justify-content-center'>
                                        //     <span className='orange-color'>
                                        //         Something Went Wrong!
                                        //     </span>
                                        // </div>
                                : null
                        }
                        {/*&nbsp;*/}
                    </td>
                </tr>
                    // : null
            ))}
            </tbody>
        </table>;
    }
}

export default Table;